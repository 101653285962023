import React, { Component } from 'react'
import '../css/Contact-Form-Clean.css'
import '../css//Newsletter-Subscription-Form.css'
import '../css/Highlight-Blue.css'

import emailjs from '@emailjs/browser';
import ReactJsAlert from "reactjs-alert";
import { Container } from 'react-bootstrap';

import "react-whatsapp-chat-widget/index.css";

export default class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
      
            message: '',
            
        };

        // This binding is necessary to make `this` work in the callback
    }



    render() {
        return (
           

                <section className="semitrans_bg">
                    <div>
                        <img src={this.state.img}></img>
                    </div>
                    <div className="container"> 
                        <div className="col-lg-12 text-center">
                            <h2 className="text-capitalize section-heading heading_font" style={{ color: "rgb(0,0,0)", marginTop: "1rem" }}>Featured Post</h2>
                            <p className="text-center subheading_font">Featured as a top cafe in woodladns on <a href="https://nearme.com.sg/food/best-cafe-woodlands/">NearMe SG</a> <br/></p>
                        </div>
                        <Container>
                                <div className="row text-center">
                                    <div className="col-md">
                                        <span className="fa-stack fa-4x" style={{paddingBottom:"16rem", paddingRight:"25rem"}}>
                                        <a href="https://nearme.com.sg/food/best-cafe-woodlands/">
                                            <img alt={"cream_pasta"} src={process.env.PUBLIC_URL + '/img/nearme.pdf'} style={{ height: "400px" }} />
                                            </a> 
                                        </span>
                                    </div>
                                    
                                </div>
                        </Container>
                    </div>
                    
    
                </section>
                    
             
        )
    }
}
